import { useEffect, useContext } from 'react'
import { useParams, NavLink } from "react-router-dom"
import { useImmer } from "use-immer"
import produce from 'immer'
import { Space, InputNumber, Input, Tag, Table, Spin, Tooltip, Button, Checkbox, notification, message, Popconfirm } from "antd"
import DealTypes from "./DealTypes"
import { EditableRow, EditableCell } from "./AntEditableRowCell"
import ProductUPCs from './ProductUPCs'
import ProductModels from "./ProductModels"
import DealStatuses from './DealStatuses'
import MessageStates from './MessageStates'
import DealActions from './DealActions'
import ExpandablePanel from './ExpandablePanel'
import SearchBar from './SearchBar'
import SideMenu from './SideMenu'
import Login from "./Login"
import { useFetchProductModels } from "./ProductModels"
import { useFetchBrands, useFetchCategories, useFetchVendors, useFetchCompanies, useFetchDealers, useFetchCountries, useFetchRegions, useFetchWaGroupNames } from './DataFetcher'
import { useFetchDealStatuses } from './DealStatuses'
import { useFetchMessageStates } from './MessageStates'
import { NOT_AVAILABLE, DEAL_TYPES } from "../Constants"
import { PlusSquareOutlined, MinusSquareOutlined, RetweetOutlined, CheckCircleTwoTone, PlusCircleOutlined, EditOutlined, CaretRightOutlined, WhatsAppOutlined, ReadOutlined } from '@ant-design/icons'
import TableFilters from './TableFilters'
import { isNum, isValidUPC, formatPhoneNumber, cropText, capitalize, getLocalTime, copyToClipboard, isValid } from '../Utils'
import { product_name, product_color, product_specs, product_part_number, product_nation, product_brand_name, product_category_name, } from '../Utils'
import { vendor_name, vendor_email, vendor_company_name, vendor_country_name, vendor_country_names_from_area_code } from '../Utils'
import { checkProductFields, checkVendorFields, checkDealFields } from "../UtilsFieldChecks"
import ProductModal from './ProductModal'
import VendorModal from './VendorModal'
import ExportWarningModal from './ExportWarningModal'
import SimilarDealsModal from './SimilarDealsModal'
import GenericWarningModal from './GenericWarningModal'
import AddProductModelModal from './AddProductModelModal'
import EditProductModelModal from './EditProductModelModal'
//import numeral from 'numeral'
import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'
import MessagePanel from './MessagePanel'
import DealerPanel from './DealerPanel'
import VendorPanel from './VendorPanel'

function DealsTable(props) {
    const { messageId, mappingId } = useParams()

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [messageApi, messageContextHolder] = message.useMessage()

    /*** TABLE STATES ***/
    const initialState = {
        deals: [],
        message_count: 0,
        vendor_count: 0,
        fromNext: false,
        isFetch: false,
        pagination: {current:1, pageSize:10, total:0, showSizeChanger: false, },
        isThereFilterChanges:false,
        queryDateTime: new Date().toUTCString(),
        resetRowSpan: false,
        isLoading: false,
    }
    let [dataSource, setDataSource] = useImmer(initialState)
    const [dealTypeState, setDealTypeState] = useImmer({payload: DEAL_TYPES, isLoading: true})
    const [upcSearchState, setUPCSearchState] = useImmer({UPC: null, productInstance: null, loadingId: -1, requestCount: 0})
    const [upcStatusState, setUPCStatusState] = useImmer({isUPCSet: {}, upcOptions: {}})
    const [productModelState, setProductModelState] = useImmer({payload: [], isLoading: true})
    const [dealStatusState, setDealStatusState] = useImmer({payload: [], isLoading: true})
    const [messageStatesState, setMessageStatesState] = useImmer({payload: [], isLoading: true})
    const [rowState, setRowState] = useImmer({rowSpan:null, expandadRows:[]})
    const [saveRow, setSaveRow] = useImmer({record: null, productInstance: null, savingId: -1, enableExport:false, fireProductReqCnt: 0, saveType:''})

    const [productData, setProductData] = useImmer({
        product_instance: null, createProductInstance: false, key: -1, requestCount: 0, zoho_product: null,
        modal: {isOpen: false}, fireVendorReqCnt: 0, isFireVendor: false, newlyCreated: false,
    })
    const [vendorData, setVendorData] = useImmer({
        vendor: null, key:-1, requestCount: 0, zoho_vendor: null,
        modal: {isOpen: false}, fireExportReqCnt: 0, isFireExport: false, newlyCreated: false,
    })
    const [exportWarningData, setExportWarningData] = useImmer({record:null, modal: {isOpen: false}})
    const [similarDealsData, setSimilarDealsData] = useImmer({data:null, fireExportReqCnt: 0, modal: {isOpen: false}})
    const [genericWarningData, setGenericWarningData] = useImmer({title:"", modal: {isOpen: false}})
    const [addProductModelData, setAddProductModelData] = useImmer({modal: {isOpen: false}})
    const [editProductModelData, setEditProductModelData] = useImmer({modal: {isOpen: false}})

    const [productNameMappingData, setProductNameMappingData] = useImmer({mapping: null,  requestCount: 0})
    //const [readMessageData, setReadMessageData] = useImmer({text:""})
    const [messageData, setMessageData] = useImmer({messageData:null, messageIndices:[], localIndex:0, selectedDealId:-1})

    /*** FILTERING ***/
    /* Default to False (Not Selected), if all options are False no filtering is applied (same as all selected) */
    const [filterOptions, setFilterOptions] = useImmer({
        colorRowId: -1,
        requestCount:0,
        searchText: '',
        startDate: null,
        endDate: null,
        messageID: '',
        contactNumbers:{selected:[], excluded:[], },
        messageKeywords:{selected:[], },
        waGroupNames:{data:[], selected:[], requestCount:0, isLoading:false},
        smallWAGroups: false,
        largeWAGroups: false,
        dealTypes:{data:DEAL_TYPES, selected:[]},
        isListEmptyUPCs: false,
        isListSpammerItems: false,
        productModels:{data:[], selected:[], requestCount:0, isLoading:false},
        brands:{data:[], selected:[], requestCount:0, isLoading:false},
        categories:{data:[], selected:[], requestCount:0, isLoading:false},
        specs:{selected:[], },
        vendors:{data:[], selected:[], requestCount:0, isLoading:false},
        companies:{data:[], selected:[], requestCount:0, isLoading:false},
        countries:{data:[], selected:[], excluded:[], requestCount:0, isLoading:false},
        regions:{data:[], selected:[], excluded:[], requestCount:0, isLoading:false},
        dealers:{data:[], selected:[], requestCount:0, isLoading:false},
        statuses:{data:[], selected:[], requestCount:0, isLoading:false},
        states:{data:[], selected:[], requestCount:0, isLoading:false}, // message states
        resetRowSpan: false,
        db: 1,
    })

    /*** SORTING ***/
    const [sortingOptions, setSortingOptions] = useImmer({
        columnName: undefined,
        sortType: undefined,
        requestCount: 0,
    })

    const resetState = () => {
        // not working
        // working version in MessageContentModal
        console.log('before')
        console.log(dataSource)
        dataSource = produce(dataSource, (draft) => {
            // Set the state to the initial state
            Object.keys(initialState).forEach((key) => {
                draft[key] = initialState[key]
            })
        })
        console.log('after')
        console.log(dataSource)
    }

    useEffect(() => {
        if (productData.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function importProduct() {
            let stopLoading = true
            setDataSource(draft => {
                draft.isLoading = true
            })

            let zoho_product = null
            let areProductsSame = false

            /*if (productData.product_instance.zoho_id === null && productData.product_instance.UPC === null) {  // export to zoho
                setProductData(draft => {
                    draft.zoho_product = zoho_product
                    draft.modal.isOpen = true
                })
                return
            }*/

            try {
                const response = await api.post(
                    'import_export_product/',
                    { 'UPC':productData.product_instance.UPC, 'zoho_id':productData.product_instance.zoho_id, 'type':'import' },
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                //console.log(productData.product)
                //console.log(response.data.zoho_product)
                zoho_product = response.data.zoho_product
                if (productData.product_instance.zoho_id !== null && zoho_product === null) {
                    setProductData(draft => {
                        draft.newlyCreated = true
                    })
                    setGenericWarningData(draft => {
                        draft.title = 'Product is created but not indexed by ZOHO yet!'
                        draft.modal.isOpen = true
                    })
                } else if (productData.product_instance.product_model === null && zoho_product === null) {
                    setGenericWarningData(draft => {
                        draft.title = `Product ${productData.product_instance.UPC} not found in ZOHO. Select or create a product model to export to ZOHO`
                        draft.modal.isOpen = true
                    })
                } else {
                    setProductData(draft => {
                        draft.newlyCreated = false
                    })
                    if (zoho_product !== null) {
                        if (product_name(productData.product_instance) === zoho_product.name &&
                            product_brand_name(productData.product_instance.product_model) === zoho_product.brand &&
                            product_category_name(productData.product_instance.product_model) === zoho_product.category &&
                            product_color(productData.product_instance) === zoho_product.color &&
                            product_specs(productData.product_instance) === zoho_product.specs &&
                            product_part_number(productData.product_instance) === zoho_product.part_number &&
                            product_nation(productData.product_instance) === zoho_product.nation &&
                            productData.product_instance.is_generic === zoho_product.is_generic
                            ) {
                                areProductsSame = true
                            }
                    }
                    stopLoading = false
                }
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to get product info from ZOHO!"
                })
                setProductData(draft => {
                    draft.zoho_product = null
                    draft.modal.isOpen = true
                })
            }

            if (stopLoading) {
                setDataSource(draft => {
                    draft.isLoading = false
                })
                setFilterOptions(draft => {
                    draft.requestCount++
                })
            } else {
                if (areProductsSame) {
                    appDispatch({
                        type: "success",
                        data: "Products are synchronized"
                    })
                    if (productData.isFireVendor) {
                        setProductData(draft => {
                            draft.fireVendorReqCnt++
                        })
                    } else {
                        setDataSource(draft => {
                            draft.isLoading = false
                        })
                    }
                } else {
                    if (checkProductFields(productData.product_instance, setGenericWarningData)) return
                    setProductData(draft => {
                        draft.zoho_product = zoho_product
                        draft.modal.isOpen = true
                    })
                }
            }
        }
        importProduct()

        return () => {
            ourRequest.cancel()
        }
    }, [productData.requestCount])

    useEffect(() => {
        if (vendorData.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function importVendor() {
            let stopLoading = true
            setDataSource(draft => {
                draft.isLoading = true
            })

            let zoho_vendor = null
            let areVendorsSame = false
            try {
                const response = await api.post(
                    'import_export_vendor/',
                    {'phone_number':vendorData.vendor.phone_number, 'type':'import'}, appState.token.post_config, { cancelToken: ourRequest.token })
                //console.log(vendorData.vendor)
                //console.log(response.data.zoho_vendor)
                zoho_vendor = response.data.zoho_vendor
                if (vendorData.vendor.zoho_id !== null && zoho_vendor === null) {
                    setVendorData(draft => {
                        draft.newlyCreated = true
                    })
                    setGenericWarningData(draft => {
                        draft.title = 'Vendor is created but not indexed by ZOHO yet!'
                        draft.modal.isOpen = true
                    })
                } else if (vendorData.vendor.company === null && zoho_vendor === null) {
                    setGenericWarningData(draft => {
                        draft.title = `Contact ${vendorData.vendor.phone_number} not found in ZOHO. Select or create a company to export to ZOHO`
                        draft.modal.isOpen = true
                    })
                } else {
                    setVendorData(draft => {
                        draft.newlyCreated = false
                    })
                    if (zoho_vendor !== null) {
                        if (vendor_name(vendorData.vendor) === zoho_vendor.full_name &&
                            vendor_email(vendorData.vendor) === zoho_vendor.email &&
                            vendor_company_name(vendorData.vendor) === zoho_vendor.company.name &&
                            vendor_country_name(vendorData.vendor) === zoho_vendor.company.country_name) {
                                areVendorsSame = true
                            }
                    }
                    stopLoading = false
                }
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to get vendor info from ZOHO!"
                })
                setVendorData(draft => {
                    draft.zoho_vendor = null
                    draft.modal.isOpen = true
                })
            }

            if (stopLoading) {
                setDataSource(draft => {
                    draft.isLoading = false
                })
                setFilterOptions(draft => {
                    draft.requestCount++
                })
            } else {
                if (areVendorsSame) {
                    appDispatch({
                        type: "success",
                        data: "Vendors are synchronized"
                    })
                    if (vendorData.isFireExport) {
                        setVendorData(draft => {
                            draft.fireExportReqCnt++
                        })
                    } else {
                        setDataSource(draft => {
                            draft.isLoading = false
                        })
                    }
                } else {
                    if (checkVendorFields(vendorData.vendor, setGenericWarningData)) return
                    setVendorData(draft => {
                        draft.zoho_vendor = zoho_vendor
                        draft.modal.isOpen = true
                    })
                }
            }
        }
        importVendor()

        return () => {
            ourRequest.cancel()
        }
    }, [vendorData.requestCount])


    useEffect(() => {
        if (productNameMappingData.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function mapNameToModel() {
            setDataSource(draft => {
                draft.isLoading = true
            })
            try {
                const response = await api.post(
                    'map_product_name_to_model/',
                    productNameMappingData.mapping, appState.token.post_config, { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "Product name is mapped"
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to map product name!"
                })
            }
            setFilterOptions(draft => {
                draft.resetRowSpan = true
                draft.requestCount++
            })
        }
        mapNameToModel()

        return () => {
            ourRequest.cancel()
        }
    }, [productNameMappingData.requestCount])


    /*** QUERY DB FOR DEALS ***/
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()
        //console.log(dataSource.queryDateTime)

        async function fetchDeals() {
            if (!appState.loggedIn) return

            /*if (!dataSource.isFetch && messageId === undefined) {
                resetState()
                return
            }*/

            if (dataSource.isThereFilterChanges) {
                setGenericWarningData(draft => {
                    draft.title = 'There are changes in filtering. Please apply them first!'
                    draft.modal.isOpen = true
                })
                return
            }

            setDataSource(draft => {
                draft.isLoading = true
            })
            try {
                let url = 'list_deals/'
                url += `?page_size=${dataSource.pagination.pageSize}`
                url += `&page=${dataSource.pagination.current}`

                if (messageId === undefined && mappingId === undefined) {
                    url += `&query_time=${dataSource.queryDateTime}`
                    url += `&db=${filterOptions.db}`

                    if (filterOptions.searchText !== "") {
                        url += `&search_text=${filterOptions.searchText}`
                    }
                    if (filterOptions.startDate !== null) {
                        url += `&start_date=${filterOptions.startDate}`
                    }
                    if (filterOptions.endDate !== null) {
                        url += `&end_date=${filterOptions.endDate}`
                    }

                    if (filterOptions.messageID !== "") {
                        url += `&message_id=${filterOptions.messageID}`
                    }
                    /*if (filterOptions.contactNumber !== "") {
                        url += `&contact_number=${filterOptions.contactNumber.replace(/\D/g, '')}`
                    }*/
                    filterOptions.contactNumbers.selected.forEach((val) => {
                        url += `&contact_numbers[]=${val.replace(/\D/g, '')}`
                    })
                    filterOptions.contactNumbers.excluded.forEach((val) => {
                        url += `&excluded_contact_numbers[]=${val.replace(/\D/g, '')}`
                    })
                    filterOptions.messageKeywords.selected.forEach((val) => {
                        url += `&message_keywords[]=${val.replace(/&/g, "[AND_SYMBOL]")}`
                    })
                    /*filterOptions.waGroupNames.selected.forEach((idx) => {
                        url += `&wa_group_names[]=${filterOptions.waGroupNames.data[idx-1].name}`
                    })*/
                    filterOptions.waGroupNames.selected.forEach((name) => {
                        url += `&wa_group_names[]=${encodeURIComponent(name)}`
                    })
                    if (filterOptions.smallWAGroups) {
                        url += '&small_wa_groups=true'
                    }
                    if (filterOptions.largeWAGroups) {
                        url += '&large_wa_groups=true'
                    }
                    filterOptions.dealTypes.selected.forEach((id) => {
                        url += `&deal_types[]=${id}`
                    })
                    if (filterOptions.isListEmptyUPCs) {
                        url += '&list_empty_upcs_only=true'
                    }
                    filterOptions.productModels.selected.forEach((id) => {
                        url += `&model_ids[]=${id}`
                    })
                    filterOptions.brands.selected.forEach((id) => {
                        url += `&brand_ids[]=${id}`
                    })
                    filterOptions.categories.selected.forEach((id) => {
                        url += `&category_ids[]=${id}`
                    })
                    filterOptions.specs.selected.forEach((val) => {
                        url += `&specs[]=${val}`
                    })
                    if (filterOptions.isListSpammerItems) {
                        url += '&list_spammer_items=true'
                    }
                    filterOptions.vendors.selected.forEach((vendor) => {
                        if (vendor.key !== undefined) {
                            url += `&vendor_ids[]=${vendor.key}`
                        }     
                    })
                    filterOptions.countries.selected.forEach((id) => {
                        url += `&country_ids[]=${id}`
                    })
                    filterOptions.countries.excluded.forEach((id) => {
                        url += `&excluded_country_ids[]=${id}`
                    })
                    filterOptions.regions.selected.forEach((id) => {
                        url += `&region_ids[]=${id}`
                    })
                    filterOptions.regions.excluded.forEach((id) => {
                        url += `&excluded_region_ids[]=${id}`
                    })
                    filterOptions.dealers.selected.forEach((id) => {
                        url += `&dealer_ids[]=${id}`
                    })
                    filterOptions.statuses.selected.forEach((id) => {
                        url += `&statuses[]=${id}`
                    })
                } else if (messageId !== undefined) {
                    url += `&message_id=${messageId}`
                } else if (mappingId !== undefined) {
                    url += `&mapping_id=${mappingId}`
                }

                if (sortingOptions.columnName !== undefined) {
                    url += `&sort_column=${sortingOptions.columnName}`
                    url += `&sort_type=${sortingOptions.sortType}`
                }

                let response = {data: {results:[], count:0, message_count:0, vendor_count:0}}

                //console.log(Axios.defaults.baseURL + url)
                if (dataSource.isFetch || messageId !== undefined || mappingId !== undefined) {
                    const startTime = performance.now()
                    response = await api.get(url, appState.token.get_config, { cancelToken: ourRequest.token })
                    const endTime = performance.now()
                    const elapsedTime = endTime - startTime // Time in milliseconds
                    console.log(`Elapsed time: ${elapsedTime} ms`)
                    //console.log(`${response.data["count"]} deals found`)
                }
                
                let rowSpan = {data: {}, prevMsgId: -1, lastRowId: -1, msgId2RowId: {}}
                let messageData = {}
                let messageIndices = []
                const dataSourceWithKey = response.data["results"].map((row) => {
                    if (row.message !== null) {
                        if (row.message.id === rowSpan.prevMsgId) {
                            rowSpan.data[row.id] = 0
                            rowSpan.data[rowSpan.lastRowId]++
                            //rowSpan.msgId2RowId[row.message.id] = rowSpan.lastRowId
                        } else {
                            rowSpan.data[row.id] = 1
                            rowSpan.lastRowId = row.id
                            rowSpan.msgId2RowId[row.message.id] = rowSpan.lastRowId
                        }
                        rowSpan.prevMsgId = row.message.id

                        if (!messageIndices.includes(row.message.id)) {
                            messageIndices.push(row.message.id)
                            messageData[row.message.id] = {
                                "msg_content": row.message.cleaned_content,
                                "msg_time": row.message.message_time,
                                "vendor": row.vendor,
                                "deal_indices": [[row.msg_start_index, row.msg_end_index]],
                                "deal_ids": [row.id],
                            }
                        } else {
                            messageData[row.message.id].deal_indices.push([row.msg_start_index, row.msg_end_index])
                            messageData[row.message.id].deal_ids.push(row.id)
                        }
                    }
                    setUPCStatusState(draft => {
                        draft.isUPCSet[row['id']] = row['product_instance'] !== null && row['product_instance']['UPC'] !== null
                    })
                    return {
                        ...row,
                        'key': row['id'], /* rowKey="id" not working */
                        'UPC_orig': row['product_instance'] === null ? '' : row['product_instance']['UPC'],
                        /*'product_instance': {
                            ...row.product_instance,
                            'is_generic': false,
                        }*/
                    }
                })

                let localIndex = 0
                if (dataSource.fromNext) {
                    localIndex = messageIndices.length - 1
                }

                setMessageData(draft => {
                    draft.messageData = messageData
                    draft.messageIndices = messageIndices
                    draft.localIndex = localIndex
                })

                rowState.expandadRows.forEach((row_id, idx) => {
                    const record = dataSourceWithKey.find((item) => row_id === item.key)
                    let r_id = (record === undefined || record.message === null) ? undefined : rowSpan.msgId2RowId[record.message.id]
                    if (r_id !== undefined) rowSpan.data[r_id]++
                })

                setRowState(draft => {
                    draft.rowSpan = rowSpan
                })
                /*if (dataSource.resetRowSpan || filterOptions.resetRowSpan || rowState.rowSpan === null) {
                    setRowState(draft => {
                        draft.rowSpan = rowSpan
                        //draft.expandadRows = []
                    })
                }*/
                setDataSource(draft => {
                    draft.resetRowSpan = false
                    draft.fromNext = false
                    draft.deals = dataSourceWithKey
                    draft.pagination.total = response.data["count"]
                    draft.message_count = response.data["message_count"]
                    draft.vendor_count = response.data["vendor_count"]
                })
                setFilterOptions(draft => {
                    draft.resetRowSpan = false
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch deals!"
                })
            }
            setDataSource(draft => {
                draft.isLoading = false
            })
        }
        fetchDeals()
        return () => {
            ourRequest.cancel()
        }
    }, [dataSource.pagination.current,
        dataSource.pagination.pageSize,
        filterOptions.requestCount,
        sortingOptions.requestCount,
        appState.loggedIn,
        messageId,
    ])

    /*** FETCH DATA ***/
    useFetchProductModels(appState, appDispatch, setProductModelState, filterOptions, setFilterOptions)
    useFetchBrands(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchCategories(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    //useFetchVendors(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchCompanies(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchCountries(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchRegions(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchDealers(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    //useFetchWaGroupNames(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchDealStatuses(appState, appDispatch, setDealStatusState, filterOptions, setFilterOptions)
    useFetchMessageStates(appState, appDispatch, setMessageStatesState, filterOptions, setFilterOptions)
    
    /*** Deal Type ***/
    function updateDealType(key, value) {
        const index = dataSource.deals.findIndex((item) => key === item.key)
        setDataSource(draft => {
            draft.deals[index].deal_type = value
        })
    }

    /*** UPC ***/
    useEffect(() => {
        if (upcSearchState.requestCount === 0) return
        if (upcSearchState.productInstance !== null) {
            setUPCStatusState(draft => {
                draft.upcOptions[upcSearchState.loadingId] = [{value: upcSearchState.productInstance.UPC}]
            })
        }
        setUPCSearchState(draft => {
            draft.UPC = null
            draft.loadingId = -1
        })
    }, [upcSearchState.requestCount])

    function onUPCSelect(key, id) {
        setUPCStatusState(draft => {
            draft.isUPCSet[id] = true
        })
        const index = dataSource.deals.findIndex((item) => key === item.key)
        setDataSource(draft => {
            draft.deals[index].product_instance = upcSearchState.productInstance
        })
    }

    function onUPCSearch(id, index, searchText) {
        if (searchText != "") {
            const index = dataSource.deals.findIndex((item) => id === item.key)
            /*setDataSource(draft => {
                draft.deals[index].product_instance.is_generic = false
            })*/
        }
        /*if (!isNum(searchText) && searchText !== "") {
            return
        }*/
        setDataSource(draft => {
            draft.deals[index].product_instance.UPC = (searchText === "") ? null : searchText
        })
        setUPCStatusState(draft => {
            draft.isUPCSet[id] = false
            draft.upcOptions[id] = []
        })
        if (isValidUPC(searchText)) {
            setUPCSearchState(draft => {
                draft.loadingId = id
                draft.UPC = searchText
            })
        }
    }

    /*** Product Name ***/
    function handleProductNameSave(row, values, dataIndex) {
        const MAX_LEN = 255
        let productName = values[dataIndex[1]]
        if (productName === "") {
            productName = NOT_AVAILABLE
        } else if (productName.length > MAX_LEN) {
            productName = productName.substring(0, MAX_LEN)
        }
        const index = dataSource.deals.findIndex((item) => row.key === item.key)
        setDataSource(draft => {
            draft.deals[index][dataIndex[0]][dataIndex[1]] = productName
        })
    }

    /*** Product Model ***/
    function updateProductModel(key, newId) {
        const modelIndex = productModelState.payload.findIndex((item) => newId === item.id)
        const selectedModel = productModelState.payload[modelIndex]

        const index = dataSource.deals.findIndex((item) => key === item.key)
        setDataSource(draft => {
            draft.deals[index].product_instance.product_model = selectedModel
        })
    }

    /*** Price ***/
    function updatePrice(key, newVal) {
        if (newVal !== "") {
            const pattern = /^\d*\.?\d*$/
            if (!pattern.test(newVal)) {  // input must include only numbers and one max dot
                return
            }
            const num_parts = newVal.split(".")  // input can be XXXXX.YY
            if (num_parts.length === 1 && num_parts[0].length > 5) {
                return
            }
            if (num_parts.length === 2) {
                if (num_parts[0].length > 5 || num_parts[1].length > 2)
                    return
            }
            //newVal = numeral(newVal).format('0,0.00')
            //newVal = Number(newVal).toLocaleString("en-US")
        }
        const index = dataSource.deals.findIndex((item) => key === item.key)
        setDataSource(draft => {
            draft.deals[index].price = newVal
        })
    }

    /*** Quantity ***/
    function updateQuantity(key, newVal) {
        if (newVal !== "") {
            const pattern = /^\d+$/
            if (!pattern.test(newVal)) {  // input must include only numbers
                return
            }
            if (newVal.length > 5) {  // input can be XXXXX
                return
            }
        }
        const index = dataSource.deals.findIndex((item) => key === item.key)
        setDataSource(draft => {
            draft.deals[index].quantity = newVal
        })
    }

    function updateMessageState(key, newId) {
        setAMessageStateState(draft => {
            draft.state = newId
            draft.messageId = key
            draft.requestCount++
        })
    }

    const [aMessageStateState, setAMessageStateState] = useImmer({state:null, messageId:null, requestCount: 0})

    useEffect(() => {
        if (aMessageStateState.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function updateStateOfAMessage() {
            const new_state_data = {
                'message_id': aMessageStateState.messageId,
                'new_message_state': aMessageStateState.state,
            }
            try {
                const response = await api.post(
                    'update_message_state/',
                    new_state_data,
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                //console.log(response.data)
                appDispatch({
                    type: "success",
                    data: "Message state updated"
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update message state!"
                })
            }
            setFilterOptions(draft => {
                draft.requestCount++
            })
        }
        updateStateOfAMessage()

        return () => {
            ourRequest.cancel()
        }
    }, [aMessageStateState.requestCount])

    
    /*** Deal Status ***/
    function updateDealStatus(key, newId) {
        /*const index = dataSource.deals.findIndex((item) => key === item.key)
        setDataSource(draft => {
            draft.deals[index].status = newId
        })*/
        setADealStatusState(draft => {
            draft.status = newId
            draft.dealId = key
            draft.requestCount++
        })
    }

    const [aDealStatusState, setADealStatusState] = useImmer({status:null, dealId:null, requestCount: 0})

    useEffect(() => {
        if (aDealStatusState.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function updateDealStatusOfADeal() {
            const new_deal_data = {
                'user_email': appState.user.email,
                'deal_id': aDealStatusState.dealId,
                'new_deal_status': aDealStatusState.status,
            }
            try {
                const response = await api.post(
                    //`update_deal/${aDealStatusState.dealId}/`, /// patch
                    'update_deal_status/',
                    new_deal_data,
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                //console.log(response.data)
                appDispatch({
                    type: "success",
                    data: "Deal updated"
                })
                /*const index = dataSource.deals.findIndex((item) => aDealStatusState.dealId === item.key)
                setDataSource(draft => {
                    draft.deals[index].deal_type = aDealStatusState.status
                })*/
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update deal!"
                })
            }
            setFilterOptions(draft => {
                draft.resetRowSpan = true
                draft.requestCount++
            })
        }
        updateDealStatusOfADeal()

        return () => {
            ourRequest.cancel()
        }
    }, [aDealStatusState.requestCount])

    function saveTableRow(record, enableExport, saveType) {
        if (enableExport) {  // export deal
            if (
                checkProductFields(record.product_instance, setGenericWarningData) ||
                checkVendorFields(record.vendor, setGenericWarningData) ||
                checkDealFields(record, setGenericWarningData)
            ) {
                return
            }
        }

        setSaveRow(draft => {
            draft.enableExport = enableExport
            draft.saveType = saveType
        })
        if (saveType === "all" || saveType === "product") {
            const UPC_orig = record.UPC_orig
            const UPC = record.product_instance.UPC

            if (upcStatusState.upcOptions[record.id] !== undefined &&
                upcStatusState.upcOptions[record.id].length > 0 && !upcStatusState.isUPCSet[record.id]) {
                //console.log("An existing UPC was entered but not selected!")
                appDispatch({
                    type: "error",
                    err: null,
                    data: "An existing UPC was entered but not selected!"
                })
            }
            else if (UPC !== "" && UPC !== null && !isValidUPC(UPC)) {
                //console.log("UPC is not valid!")
                appDispatch({
                    type: "error",
                    err: null,
                    data: "UPC is not valid!"
                })
            }
            else {
                /* UPC changed create new product instance */
                if (UPC_orig !== null && UPC_orig !== UPC) {
                    setSaveRow(draft => {
                        draft.productInstance = record.product_instance
                        draft.record = record
                        draft.savingId = record.id
                    })
                } else {
                    setSaveRow(draft => {
                        draft.productInstance = null
                        draft.record = record
                        draft.savingId = record.id
                    })
                }
            }
        } else {
            setSaveRow(draft => {
                draft.productInstance = null
                draft.record = record
                draft.savingId = record.id
            })
        }
    } /* end of saveTableRow */

    /*useEffect(() => {
        if (saveRow.fireProductReqCnt === 0) return
        importProductFromZOHO(saveRow.record, true)
    }, [saveRow.fireProductReqCnt])*/

    useEffect(() => {
        if (productData.fireVendorReqCnt === 0) return
        importVendorFromZOHO(saveRow.record, true)
    }, [productData.fireVendorReqCnt])

    useEffect(() => {
        if (vendorData.fireExportReqCnt === 0) return

        let warningMsg = ''
        if (productData.newlyCreated) {
            warningMsg = 'Product is created but not indexed by ZOHO yet!'
        } else if (vendorData.newlyCreated) {
            warningMsg = 'Vendor is created but not indexed by ZOHO yet!'
        }

        if (warningMsg !== '') {
            setGenericWarningData(draft => {
                draft.title = warningMsg
                draft.modal.isOpen = true
            })
            //updateDealStatus(saveRow.record.key, 'SAVED')
            /*setDataSource(draft => {
                draft.isLoading = false
            })*/
            setFilterOptions(draft => {
                //draft.colorRowId = saveRow.record.id
                draft.requestCount++
            })
        } else {
            const ourRequest = Axios.CancelToken.source()

            // QUERY DEALS
            async function querySimilarDeals() {
                setDataSource(draft => {
                    draft.isLoading = true
                })
                try {
                    const response = await api.post(
                        'query_deals/',
                        saveRow.record,
                        appState.token.post_config,
                        { cancelToken: ourRequest.token }
                    )
                    if (response.data.length > 0) {
                        setSimilarDealsData(draft => {
                            draft.data = response.data
                            draft.modal.isOpen = true
                        })
                    } else {
                        setSimilarDealsData(draft => {
                            draft.fireExportReqCnt++
                        })
                    }
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to query deals!"
                    })
                    setDataSource(draft => {
                        draft.isLoading = false
                    })
                }
            }
            querySimilarDeals()

            return () => {
                ourRequest.cancel()
            }
        }
    }, [vendorData.fireExportReqCnt])

    useEffect(() => {
        if (similarDealsData.fireExportReqCnt === 0) return

        const ourRequest = Axios.CancelToken.source()

        // SAVE DEAL to ZOHO
        async function createDeal() {
            setDataSource(draft => {
                draft.isLoading = true
            })
            let zoho_id = null
            //console.log(saveRow.record)
            try {
                const response = await api.post(
                    'export_deal/',
                    saveRow.record,
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "Deal created in ZOHO"
                })
                zoho_id = response.data
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to create deal in ZOHO!"
                })
            }
            if (zoho_id !== null) {
                //updateZohoId(saveRow.record.key, zoho_id, 'Deal')
                //saveTableRow(saveRow.record, false)
                const new_deal_data = {
                    'status': "EXPORTED",
                    'zoho_id': zoho_id,
                }
                try {
                    const response = await api.patch(
                        `update_deal/${saveRow.record.id}/`,
                        new_deal_data,
                        appState.token.post_config
                    )
                    //console.log(response.data)
                    appDispatch({
                        type: "success",
                        data: "Deal updated"
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to update deal!"
                    })
                }
                setFilterOptions(draft => {
                    //draft.colorRowId = saveRow.record.id
                    draft.requestCount++
                })
            }
            setDataSource(draft => {
                draft.isLoading = false
            })
        }
        createDeal()

        return () => {
            ourRequest.cancel()
        }

    }, [similarDealsData.fireExportReqCnt])

    function exportToZoho(record) {
        setProductData(draft => {
            draft.newlyCreated = false
        })
        setVendorData(draft => {
            draft.newlyCreated = false
        })
        setExportWarningData(draft => {
            draft.record = record
            draft.modal.isOpen = true
        })
    }

    function setIsUPCSet(id, val) {
        setUPCStatusState(draft => {
            draft.isUPCSet[id] = val
        })
    }

    // can be deleted and saveTableRow can be directly called
    function importProductFromZOHO(record, isFireVendor) {
        if (!isFireVendor) {
            saveTableRow(record, isFireVendor, 'product')
        }
    }

    function importVendorFromZOHO(record, isFireExport) {
        if (isFireExport) {
            saveTableRow(record, isFireExport, 'vendor_export_deal')
        } else {  // not used
            saveTableRow(record, isFireExport, 'vendor')
        }
    }

    // can be deleted
    function updateZohoId(key, zoho_id, entity_name) {
        const index = dataSource.deals.findIndex((item) => key === item.key)
        if (entity_name === 'Deal') {
            setDataSource(draft => {
                draft.deals[index].status = "EXPORTED"
                draft.deals[index].zoho_id = zoho_id
            })
        } else if (entity_name === 'Vendor') {
            setDataSource(draft => {
                draft.deals[index].vendor.zoho_id = zoho_id
            })
        } else if (entity_name === 'Product') {
            setDataSource(draft => {
                draft.deals[index].product_instance.zoho_id = zoho_id
            })
        }
    }

    function addNewProductModel() {
        setAddProductModelData(draft => {
            draft.modal.isOpen = true
        })
    }

    function editProductModel() {
        setEditProductModelData(draft => {
            draft.modal.isOpen = true
        })
    }

    function onIsGenericChange(key, e) {
        const index = dataSource.deals.findIndex((item) => key === item.key)
        setDataSource(draft => {
            draft.deals[index].product_instance.is_generic = e.target.checked
        })
    }

    function onIsSpammerChange(record, is_spammer) {
        const index = dataSource.deals.findIndex((item) => record.key === item.key)
        setDataSource(draft => {
            draft.deals[index].vendor.is_spammer = is_spammer
        })
        if (is_spammer) {
            saveTableRow(record, false, 'vendor_spam')
        } else {
            saveTableRow(record, false, 'vendor_unspam')
        }
    }

    function mapProductToModel(record) {
        if (record.product_instance === null) return
        if (record.product_instance.name === null || record.product_instance.name === "" || record.product_instance.name === NOT_AVAILABLE) {
            appDispatch({
                type: "error",
                err: null,
                data: "Product Name can not be empty!"
            })
        } else {
            const product_model_id = record.product_instance.product_model === null ? -1 : record.product_instance.product_model.id
            if (product_model_id === -1) {
                appDispatch({
                    type: "error",
                    err: null,
                    data: "Product Model must be selected!"
                })
            } else {
                setProductNameMappingData(draft => {
                    draft.mapping = {
                        'original_name': record.product_instance.original_name,
                        'name': record.product_instance.name,
                        'product_id': record.product_instance.id,
                        'product_model_id': product_model_id,
                    }
                    draft.requestCount++
                })
            }
        }
    }

    function isFieldDisabled(record) {
        if (record.status !== "SAVED" || record.saved_by !== appState.user.email)
            return true
        return false
    }

    function isStatusDisabled(record) {
        if (record.saved_by === null || record.saved_by === appState.user.email)
            return false
        return true
    }

    const defaultColumns = [
        {
            title: 'Msg Time',
            width: 70,
            onCell: (record, index) => {
                return {
                    rowSpan: rowState.rowSpan.data[record.id],
                }
            },
            render: (_, record) => {
                //const msg_time = new Date(Date.parse(record.message.message_time)).toLocaleString()
                const msg_time = record.message === null ? '' : getLocalTime(record.message.message_time, false)
                //const msg_content = record.message === null ? '' : cropText(record.message.cleaned_content, 150)
                //const msg_content = record.message === null ? '' : record.message.cleaned_content

                let cellColor = "white"
                let msgIdx = -1
                if (record.message !== null) {
                    if (messageData.messageIndices[messageData.localIndex] === record.message.id) {
                        cellColor = "#FFEFA5"
                    }
                    msgIdx = messageData.messageIndices.indexOf(record.message.id) + 1
                }
                return {
                    props: {
                        style: { background: cellColor, }
                    },
                    children:
                        <div style={{ background: cellColor, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                            onClick={() => setMessageData(draft => { draft.localIndex = msgIdx - 1})}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                {record.message !== null ?
                                <div>
                                    <Tooltip title="Open Message in New Tab" placement="bottom">
                                        <NavLink to={`/messages/message/${record.message.id}`} target="_blank" rel="noopener noreferrer" className="nav-link">
                                            <p className='message-id'>
                                                {msg_time.split(",")[0]}{msg_time.split(",")[1]}
                                            </p>
                                        </NavLink>
                                    </Tooltip>
                                    <p style={{paddingLeft:16}}>
                                        {`(${msgIdx})`}
                                    </p>
                                </div> : ""}
                                
                                {/*<Tooltip
                                    placement="bottom"
                                    //title={<p className='withprewrap'>{msg_content}</p>}
                                    title="Read Message"
                                >
                                    <ReadOutlined
                                        style={{fontSize: '24px', marginLeft:'15px', }}
                                        //className='btn-style-green'
                                        onClick={()=>setReadMessageData(draft=>{draft.text = msg_content})}
                                    />
                                </Tooltip>*/}
                            </div>
                        </div>
                }
            }
        },
        {
            title: 'Status',
            //width: 125,
            render: (_, record) => {
                return (
                    <Space direction="vertical" size="small">
                        <Space direction="horizontal" size="small">
                            <DealStatuses
                                zoho_id={record.zoho_id}
                                disabled={isStatusDisabled(record)}
                                selected={record.status}
                                onChange={(newValue) => {
                                    updateDealStatus(record.key, newValue)
                                }}
                                state={dealStatusState}
                                setState={setDealStatusState}/>
                            <Tooltip title={record.zoho_id !== null ? `Deal ZOHO Id: ${record.zoho_id.toString()}` : ''} placement="bottom">
                                {record.zoho_id !== null ? <CheckCircleTwoTone twoToneColor="#52c41a"
                                    onClick={(e) => {
                                        copyToClipboard(record.zoho_id.toString())
                                        messageApi.open({
                                            type: 'success',
                                            content: 'Copied!',
                                            //className: 'no-trans',
                                            duration: '1.',
                                        })
                                    }} /> : ''}
                            </Tooltip>
                        </Space>
                        <Tooltip title={record.saved_by !== null ? `Claimed by ${record.saved_by.toString()}` : ''} placement="bottom">
                            <small style={{paddingLeft: '5px'}}>{record.id}</small>
                        </Tooltip>
                    </Space>
                )
            }
        },
        {
            title: 'Actions',
            //width: 100,
            render: (_, record) => {
                return (
                    <DealActions
                        saving={saveRow.savingId === record.id}
                        onSaveClick={() => saveTableRow(record, false, 'all')}
                        onExportClick={() => exportToZoho(record)}
                        state={saveRow}
                        setState={setSaveRow}
                        setDataSource={setDataSource}
                        setProductData={setProductData}
                        setVendorData={setVendorData}
                        setFilterState={setFilterOptions}
                        setIsUPCSet={setIsUPCSet}
                        displaySave = {record.zoho_id === null && !isFieldDisabled(record)}
                        displayExport={
                            record.product_instance !== null &&
                            (isValidUPC(record.product_instance.UPC)/* || record.product_instance.is_generic*/) &&
                            record.zoho_id === null &&
                            record.deal_type !== "-None-" &&
                            !isFieldDisabled(record)
                        }
                    />
                )
            }
        },
        {
            title: 'Type',
            //width: 85,
            render: (_, record) => <DealTypes
                                        disabled={record.zoho_id !== null || isFieldDisabled(record)}
                                        selected={record.deal_type}
                                        onChange={(newValue) => {
                                            updateDealType(record.key, newValue)
                                        }}
                                        state={dealTypeState}
                                        setState={setDealTypeState}/>
        },
        {
            title: <div style={{textDecoration:'underline'}}>UPC</div>,
            //width: 130,
            render: (_, record, index) => <Space direction="horizontal" size="small">
                                                <Space direction="vertical" size="small">
                                                    <ProductUPCs
                                                        disabled={
                                                            record.product_instance === null ||
                                                            record.zoho_id !== null ||
                                                            isFieldDisabled(record)/* ||
                                                            record.product_instance.is_generic ||
                                                            upcSearchState.loadingId === record.id*/
                                                        }
                                                        value={record.product_instance === null ? '' : record.product_instance.UPC}
                                                        options={upcStatusState.upcOptions[record.id]}
                                                        loading={upcSearchState.loadingId === record.id}
                                                        onSelect={() => {
                                                            onUPCSelect(record.key, record.id)
                                                        }}
                                                        onSearch={(newValue) => {
                                                            onUPCSearch(record.id, index, newValue)
                                                        }}
                                                        state={upcSearchState}
                                                        setState={setUPCSearchState}/>
                                                    <Checkbox
                                                        disabled={
                                                            record.product_instance === null ||
                                                            record.zoho_id !== null ||
                                                            isFieldDisabled(record)
                                                            /* || record.product_instance.UPC !== null*/}
                                                        key={"g"+record.id}
                                                        onChange={(e) => { onIsGenericChange(record.key, e) }}
                                                        checked={record.product_instance === null ? false : record.product_instance.is_generic}
                                                        style={{fontSize:'12px'}}
                                                    >
                                                        Generic Item
                                                    </Checkbox>
                                                </Space>
                                                {messageContextHolder}
                                                {record.product_instance !== null &&
                                                 record.status !== "EXPORTED" &&
                                                 !isFieldDisabled(record) &&
                                                (
                                                    isValidUPC(record.product_instance.UPC) /*||
                                                    record.product_instance.zoho_id !== null ||
                                                    record.product_instance.is_generic*/
                                                ) ?
                                                <Tooltip title="Import/Export Product from/to ZOHO" placement="bottom">
                                                    <Button className='message-panel-button btn-style' size='small' icon={<RetweetOutlined />}
                                                            onClick={(e) => {
                                                                importProductFromZOHO(record, false)
                                                            }} />
                                                </Tooltip> : ''}
                                                <Tooltip title={(record.product_instance !== null && record.product_instance.zoho_id !== null) ? `Product ZOHO Id: ${record.product_instance.zoho_id.toString()}` : ''}
                                                        placement="bottom">
                                                        {record.product_instance !== null && record.product_instance.zoho_id !== null ? <CheckCircleTwoTone twoToneColor="#52c41a"
                                                            onClick={(e) => {
                                                                copyToClipboard(record.product_instance.zoho_id.toString())
                                                                messageApi.open({
                                                                    type: 'success',
                                                                    content: 'Copied!',
                                                                    //className: 'no-trans',
                                                                    duration: '1.',
                                                                })
                                                        }} /> : ''}
                                                </Tooltip>
                                            </Space>
        },
        {
            title: <div style={{textDecoration:'underline'}}>Product Name</div>,
            dataIndex: ['product_instance', 'name'],
            editable: true,
        },
        {
            title: (
                <div>
                    <AddProductModelModal
                        state={addProductModelData}
                        setState={setAddProductModelData}
                        setFilterOptions={setFilterOptions}
                        productModels={productModelState.payload}
                        brands={filterOptions.brands.data}
                        categories={filterOptions.categories.data} />
                    <EditProductModelModal
                        state={editProductModelData}
                        setState={setEditProductModelData}
                        setFilterOptions={setFilterOptions}
                        productModels={productModelState.payload}
                        brands={filterOptions.brands.data}
                        categories={filterOptions.categories.data} />
                    <div>
                        <div style={{textDecoration:'underline'}}>Model - Brand - Category</div>
                        <Tooltip title="Add New Product Model" placement="bottom">
                            <Button className='message-panel-button btn-style' size='small' icon={<PlusCircleOutlined />}
                                onClick={(e) => {
                                    addNewProductModel()
                                }} />
                        </Tooltip>
                        <Tooltip title="Edit A Product Model" placement="bottom">
                            <Button className='message-panel-button btn-style-red' size='small' icon={<EditOutlined />}
                                onClick={(e) => {
                                    editProductModel()
                                }} />
                        </Tooltip>
                    </div>
                </div>
            ),
            //width: 200,
            render: (_, record) => {
                let selected = NOT_AVAILABLE
                if (isValid(record.product_instance) && isValid(record.product_instance.product_model)) {
                    selected = record.product_instance.product_model.id
                }
                let brand = NOT_AVAILABLE
                let category = NOT_AVAILABLE
                if (isValid(record.product_instance) && isValid(record.product_instance.product_model)) {
                    if (isValid(record.product_instance.product_model.brand)) {
                        brand = record.product_instance.product_model.brand.name
                    }
                    if (isValid(record.product_instance.product_model.category)) {
                        category = record.product_instance.product_model.category.name
                    }
                }
                const tag_color = category === NOT_AVAILABLE ? '#ff7875' : ""
                return (
                        <Space direction="horizontal" size="small">
                            {isFieldDisabled(record) ? "" :
                            <Tooltip title="Map Product Name to Model" placement="bottom">
                                <CaretRightOutlined
                                    style={{fontSize: '22px', borderRadius: '11px', /*marginLeft:'-15px', marginTop:'33px'*/}}
                                    className='btn-style-green'
                                    size='small'
                                    //icon={<CaretRightOutlined />}
                                    onClick={()=>mapProductToModel(record)}
                                />
                            </Tooltip>}
                            <Space direction="vertical" size="small">
                                <ProductModels
                                        disabled={/*upcStatusState.isUPCSet[record.key] ||*/ record.zoho_id !== null || isFieldDisabled(record)}
                                        showSearch
                                        selected={selected}
                                        onChange={(newValue) => {
                                            updateProductModel(record.key, newValue)
                                        }}
                                        state={productModelState}
                                        setState={setProductModelState}/>
                                <Space size="small">
                                    <Tag style={{whiteSpace:'pre-wrap', maxWidth:'105px'}}>{brand}</Tag>
                                    <Tag style={{whiteSpace:'pre-wrap', maxWidth:'105px', borderColor:tag_color}}>{category}</Tag>
                                </Space>
                            </Space>
                        </Space>
                )
            }
        },
        {
            title: 'Price',
            //width: 105,
            sorter: true,
            render: (_, record) => {
                const color = record.price_currency !== "USD" ? "red" : ""
                return (
                    <Space size="small">
                        <small style={{color: color}}>{record.price_currency}</small>
                        <Input
                            disabled={record.zoho_id !== null || isFieldDisabled(record)}
                            size='small'
                            addonBefore="$"
                            value={record.price}
                            onChange={(e) => {updatePrice(record.key, e.target.value)}}
                            style={{
                                width: 115,
                            }}
                        />
                    </Space>
                )
            }
        },
        {
            title: 'Quantity',
            //width: 80,
            sorter: true,
            render: (_, record) => {
                return (
                    <Input
                        disabled={record.zoho_id !== null || isFieldDisabled(record)}
                        size='small'
                        value={record.quantity}
                        onChange={(e) => {updateQuantity(record.key, e.target.value)}}
                        style={{
                            width: 75,
                            borderColor: record.quantity === null || record.quantity === "" ? "#ff7875" : ""
                        }}
                    />
                )
            }
        },
        {
            title: 'Contact',
            dataIndex: ['vendor', 'full_name'],
            //width: 50,
            render: (_, record) => {
                const vendor_name = record.vendor === null ? '' : record.vendor.full_name
                //const phoneNumber = formatPhoneNumber(vendor_name)
                //const displayText = phoneNumber !== null ? phoneNumber : vendor_name
                const displayText = vendor_name
                return (
                    record.vendor === null ? '' :
                    <Space direction="horizontal" size="small">
                        <Popconfirm
                            title="Are you sure?"
                            //description=""
                            onConfirm={(e) => { onIsSpammerChange(record, !record.vendor.is_spammer) }}
                            //onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title={record.vendor.is_spammer ? 'Unmark as Spammer' : 'Mark as Spammer'} placement="bottom">
                                <Checkbox
                                    key={"s"+record.id}
                                    checked={record.vendor.is_spammer}
                                />
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip title={record.vendor.zoho_id !== null ? `Vendor ZOHO Id: ${record.vendor.zoho_id.toString()}` : ''} placement="bottom">
                            {record.vendor.zoho_id !== null ? <CheckCircleTwoTone twoToneColor="#52c41a"
                                onClick={(e) => {
                                    copyToClipboard(record.vendor.zoho_id.toString())
                                    messageApi.open({
                                        type: 'success',
                                        content: 'Copied!',
                                        //className: 'no-trans',
                                        duration: '1.',
                                    })
                                }} /> : ''}
                        </Tooltip>
                        <Tooltip title={vendor_country_names_from_area_code(record.vendor)} placement="bottom">
                            <p className='vendor-style'>{displayText}</p>
                        </Tooltip>
                        <Tooltip title="Copy Contact Number" placement="bottom">
                            <WhatsAppOutlined
                                style={{fontSize: '18px', borderRadius: '9px', /*marginLeft:'-15px', marginTop:'33px'*/}}
                                className='btn-style-green'
                                size='small'
                                onClick={(e) => {
                                    //sendMessage(props.record.vendor.phone_number, "")
                                    copyToClipboard(record.vendor.phone_number)
                                    messageApi.open({
                                        type: 'success',
                                        content: 'Copied!',
                                        //className: 'no-trans',
                                        duration: '1.',
                                    })
                                }} />
                        </Tooltip>
                    </Space>
                )
            }
        },
    ]

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                upcStatusState,
                handleProductNameSave,
                isFieldDisabled,
            }),
        }
    })

    /*** Table Related Functions ***/
    function handleTableChange(pagination, filters, sorter) {
        setDataSource(draft => {
            draft.pagination.current = pagination.current
            draft.pagination.pageSize = pagination.pageSize
            draft.resetRowSpan = true
        })
        setSortingOptions(draft => {
            draft.columnName = sorter.column?.title
            draft.sortType = sorter.order
            draft.requestCount++
        })
    }

    /*const container = document.getElementById("container")

    const onWheel = e => {
        //e.preventDefault()
        const containerScrollPosition = container.scrollLeft
        container.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY,
            behaviour: "smooth"
        })
    }

    <div className="container" id="container" onWheel={onWheel}/>*/

    function updateField(fieldName, key, e) {
        const index = dataSource.deals.findIndex((item) => key === item.key)
        if (fieldName === 'price_desc') {
            let price_desc = e.target.value
            if (price_desc.length > 35)
                price_desc = price_desc.substring(0, 35)
            setDataSource(draft => {
                draft.deals[index].price_desc = price_desc
            })
        } else if (fieldName === 'location') {
            let location = e.target.value
            if (location.length > 35)
                location = location.substring(0, 35)
            setDataSource(draft => {
                draft.deals[index].location = location
            })
        } else if (fieldName === 'user_note') {
            setDataSource(draft => {
                draft.deals[index].user_note = e.target.value
            })
        } else if (fieldName === 'part_number') {
            let part_number = e.target.value
            if (part_number.length > 35)
                part_number = part_number.substring(0, 35)
            setDataSource(draft => {
                draft.deals[index].product_instance.part_number = part_number
            })
        } else if (fieldName === 'nation') {
            let nation = e.target.value
            if (nation.length > 35)
                nation = nation.substring(0, 35)
            setDataSource(draft => {
                draft.deals[index].product_instance.nation = nation
            })
        } else if (fieldName === 'color') {
            let color = capitalize(e.target.value)
            if (color.length > 35)
                color = color.substring(0, 35)
            setDataSource(draft => {
                draft.deals[index].product_instance.color = color
            })
        } else if (fieldName === 'specs') {
            setDataSource(draft => {
                draft.deals[index].product_instance.specs = e.target.value
            })
        } else if (fieldName === 'vendor_name') {
            let vendor_name = e.target.value
            if (vendor_name.length > 255)
                vendor_name = vendor_name.substring(0, 255)
            setMessageData(draft => {
                draft.messageData[key].vendor.full_name = vendor_name
            })
        } else if (fieldName === 'vendor_email') {
            let vendor_email = e.target.value
            if (vendor_email === '') {
                setMessageData(draft => {
                    draft.messageData[key].vendor.email = null
                })
            }
            if (vendor_email.length > 255)
                vendor_email = vendor_email.substring(0, 255)
            setMessageData(draft => {
                draft.messageData[key].vendor.email = vendor_email
            })
        } else if (fieldName === 'vendor_company') {
            const companyIndex = filterOptions.companies.data.findIndex((item) => e === item.id)
            let selectedCompany = filterOptions.companies.data[companyIndex]
            if (selectedCompany.id === NOT_AVAILABLE)
                selectedCompany = null
            setMessageData(draft => {
                draft.messageData[key].vendor.company = selectedCompany
            })
        } else if (fieldName === 'new_company') {
            setMessageData(draft => {
                draft.messageData[key].vendor.company = e
            })
        } else if (fieldName === 'company_name') {
            let company_name = e.target.value
            if (company_name.length > 255)
                company_name = company_name.substring(0, 255)
            setMessageData(draft => {
                draft.messageData[key].vendor.company.name = company_name
            })
            /*const companyIndex = dataSource.deals[index].vendor.company.id
            setFilterOptions(draft => {
                draft.companies.data[companyIndex].name = e.target.value
            })*/
        } else if (fieldName === 'company_country') {
            const countryIndex = filterOptions.countries.data.findIndex((item) => e === item.id)
            const selectedCountry = filterOptions.countries.data[countryIndex]
            setMessageData(draft => {
                draft.messageData[key].vendor.company.country = selectedCountry
            })
        }
    }

    function setLoading(isLoading) {
        setDataSource(draft => {
            draft.isLoading = isLoading
        })
    }

    function setProduct(selectedProduct, key) {
        const index = dataSource.deals.findIndex((item) => key === item.key)
        if (selectedProduct.UPC !== null) {
            setUPCStatusState(draft => {
                draft.isUPCSet[key] = true
            })
        }
        // we directly set the product because of zoho_id
        // product selected from list of products where zoho_id is not null
        setDataSource(draft => {
            draft.deals[index].product_instance = selectedProduct
        })

        /*if (selectedProduct.UPC !== null) {
            
        } else {
            setDataSource(draft => {
                draft.deals[index].product_instance.name = selectedProduct.name
                // ...
            })
        }*/     
    }

    const tableLoading = {
        spinning: dataSource.isLoading,
        indicator: <Spin size="large" />,
    }

    if (!appState.loggedIn) return <Login />

    return (
        <div className='App'>
            <SideMenu connected={props.connected} />
            <Space size="small" direction='horizontal' align='start' style={{paddingLeft: 15, paddingTop: 10}}>
                <TableFilters
                    tableName="Deal"
                    disabled={dataSource.isLoading}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    messageId={messageId}/>
                <div direction="vertical" size="small">
                    <SearchBar
                        disabled={dataSource.isLoading}
                        filterOptions={filterOptions}
                        setFilterOptions={setFilterOptions}
                        dataSource={dataSource}
                        setDataSource={setDataSource}/>
                    <ProductModal
                        state={productData}
                        setState={setProductData}
                        setDataSource={setDataSource}
                        updateZohoId={updateZohoId}
                        setFilterOptions={setFilterOptions}
                        productModels={filterOptions.productModels.data}
                        brands={filterOptions.brands.data}
                        categories={filterOptions.categories.data} />
                    <VendorModal
                        state={vendorData}
                        setState={setVendorData}
                        setDataSource={setDataSource}
                        updateZohoId={updateZohoId}
                        setFilterOptions={setFilterOptions}
                        companies={filterOptions.companies.data}
                        countries={filterOptions.countries.data}
                    />
                    <SimilarDealsModal
                        state={similarDealsData}
                        setState={setSimilarDealsData}
                        setDataSource={setDataSource}
                    />
                    <ExportWarningModal
                        state={exportWarningData}
                        setState={setExportWarningData}
                        setDataSource={setDataSource}
                        saveTableRow={saveTableRow}
                    />
                    <GenericWarningModal
                        state={genericWarningData}
                        setDataSource={setDataSource}
                        setState={setGenericWarningData}
                    />
                    <div className="table-container">
                        <Table
                            loading={tableLoading}
                            style={{width: 1444, }}
                            components={components}
                            className="table-striped-rows"
                            rowClassName={(record, index) => record.id === filterOptions.colorRowId ? 'editable-row, color-row' : 'editable-row'}
                            //rowClassName={(record, index) => 'editable-row'}
                            //tableLayout='auto'
                            //scroll={{ x: true }}
                            bordered={{ border: 'none', }}
                            dataSource={dataSource.deals}
                            columns={columns}
                            /*onRow={(record, index) => ({
                                onMouseEnter: () => setReadMessageData(draft => {draft.text = record.message === null ? "" : record.message.cleaned_content}),
                            })}*/
                            onChange={handleTableChange}
                            pagination={dataSource.pagination}
                            expandedRowKeys={rowState.expandadRows}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <ExpandablePanel record={record}
                                                    updateField={updateField}
                                                    isFieldDisabled={isFieldDisabled}
                                                    filterOptions={filterOptions}
                                                    setFilterOptions={setFilterOptions}
                                                    setLoading={setLoading}
                                                    setProduct={setProduct}/>
                                ),
                                expandIconColumnIndex: 1,
                                expandIcon: ({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusSquareOutlined style={{ fontSize: '18px', color: record.id === messageData.selectedDealId ? "#dfa814" : "" }} onClick={e => {
                                                    onExpand(record, e)
                                                    let r_id = rowState.rowSpan.msgId2RowId[record.message.id]
                                                    if (r_id === undefined) r_id = record.id
                                                    const index = rowState.expandadRows.indexOf(record.key)
                                                    setRowState(draft => {
                                                        draft.expandadRows.splice(index, 1)
                                                        draft.rowSpan.data[r_id]--
                                                    })
                                                }}/>
                                            ) : (
                                                <PlusSquareOutlined style={{ fontSize: '18px', color: record.id === messageData.selectedDealId ? "#dfa814" : "" }} onClick={e => {
                                                    if (record.message === null) {
                                                        appDispatch({
                                                            type: "error",
                                                            err: null,
                                                            data: "Message not found!!!"
                                                        })
                                                    } else {
                                                        onExpand(record, e)
                                                        let r_id = record.message === null ? undefined : rowState.rowSpan.msgId2RowId[record.message.id]
                                                        if (r_id === undefined) r_id = record.id
                                                        setRowState(draft => {
                                                            draft.expandadRows.push(record.key)
                                                            draft.rowSpan.data[r_id]++
                                                        })
                                                    }
                                                }}/>
                                            )
                            }}
                        />
                    </div>
                </div>
                {messageData.messageData === null ? "" :
                <div style={{paddingBottom:'20px'}}>
                    {
                        (messageId === undefined || dataSource.deals.length === 0) ? "" :
                        <div style={{marginTop:'10px', padding:'5px', backgroundColor:'#FFEFA5', borderRadius:4}} >
                            <strong style={{paddingRight:25}}>Message State:</strong>
                            <MessageStates
                                disabled={false}
                                selected={dataSource.deals[0].message.state}
                                onChange={(newValue) => {
                                    updateMessageState(messageId, newValue)
                                }}
                                state={messageStatesState}
                                setState={setMessageStatesState}/>
                        </div>
                    }
                    <MessagePanel
                        marginTop={messageId === undefined ? "50px": "6px"}
                        messageData={messageData}
                        setMessageData={setMessageData}
                        dataSource={dataSource}
                        setDataSource={setDataSource}
                    />
                    <VendorPanel
                        messageData={messageData}
                        filterOptions={filterOptions}
                        setFilterOptions={setFilterOptions}
                        dataSource={dataSource}
                        updateField={updateField}
                        setVendorData={setVendorData}
                    />
                    <DealerPanel
                        messageData={messageData}
                        filterOptions={filterOptions}
                        dataSource={dataSource}
                    />
                </div>
                }
            </Space>
        </div>
    )
}

export default DealsTable
