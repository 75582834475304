import { Modal, Button, message, Tooltip } from "antd"
import { CopyOutlined } from '@ant-design/icons'
import { copyToClipboard } from '../Utils'

function GenericWarningModal(props) {

    const [messageApi, contextHolder] = message.useMessage()

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            //className: 'no-trans',
            duration: '1.',
        })
    }

    function closeModal() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setDataSource(draft => {
            draft.isLoading = false
        })
    }

    function handleOk() {
        closeModal()
    }

    function handleCancel() {
        closeModal()
    }

    function footer() {
        let footerList = []
        if (props.displayCopyButton) {
            footerList.push(
                <Tooltip title={"Copy content"} placement="bottom">
                    <Button key={"copy"} size='small' icon={<CopyOutlined />}
                        onClick={(e) => {
                            //copyToClipboard(cropText(props.record.message.cleaned_content, 100))
                            copyToClipboard(props.state.title)
                            success("Copied!")
                        }} />
                </Tooltip>
            )
        }
        if (props.notDisplayOkButton)
            return footerList
        footerList.push(
            <Button key="submit" type="primary" onClick={handleOk}>
                OK
            </Button>
        )
        return footerList
    }

    return (
        <Modal
            centered
            title=""
            open={props.state.modal.isOpen}
            onCancel={handleCancel}
            footer={footer()}
            >
                {contextHolder}
                <span className='withprewrap'>{props.state.title}</span>
        </Modal>
    )
}

export default GenericWarningModal
