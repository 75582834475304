import { useEffect, useContext, useRef } from 'react'
import { useImmer } from "use-immer"
//import produce from 'immer'
import { Modal, Button, message, Space, Select, Popconfirm, Tooltip, Spin, Table, Tabs, Input } from "antd"
import { CopyOutlined, PlusCircleOutlined, FileExcelOutlined, FileImageOutlined,
    UploadOutlined, CaretLeftOutlined, CloseOutlined, PicCenterOutlined, DownloadOutlined } from '@ant-design/icons'
import { base64ToBlob, copyToClipboard, cropText, isExcelFile } from '../Utils'
import TextArea from "antd/es/input/TextArea"
import * as XLSX from "xlsx"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import OfferExportRow from './OfferExportRow'
import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength, parsePhoneNumberFromString  } from 'libphonenumber-js'

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


const { Option } = Select


function MessageContentModal(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [messageApi, contextHolder] = message.useMessage()

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            //className: 'no-trans',
            duration: '1.',
        })
    }

    // value = message content
    const [messageState, setMessageState] = useImmer({
        value:"", categoriesFetched:null, categoryList:[], subcategoryList:[], actionList:[],
        isLoading:false, offerExportIsLoading:false, phoneNumber:"", isMsgSaved:false, saveMsgReqCnt:0
    })

    const vendorCommonTexts = ["Devin Tech"]

    const [acState, setAcState] =  useImmer({vendorOptions:[]})

    const initialFieldsState = {
        key: 1,
        vendor: "",
        category: null,
        subcategory: null,
        model: "",
        quantity: "",
        cost: "",
        offer_exw: "",
        offer_landed: "",
        offer_cnf: "",
        maxdiscount: "",
        location: "",
        nomianl: "",
        owner: "",
        eta: "",
        prebook: false,
        actions: [],
        actions_completed: "",
        note: "",
        sales_notes: "",
        priority: false,
        featured: false,
        bundle: "",
        share: false,
        exportId:-1,
    }
    
    let [fieldsState, setFieldsState] = useImmer({liste:[initialFieldsState], exportReqCnt:0})

    const [fileStates, setFileStates] = useImmer({isDragging:false, files:[], savedFiles:[], previewFile:null, isLoading:false,})

    const pasteAreaRef = useRef(null)

    useEffect(() => {
        setFileStates(draft => {draft.previewFile = null})
        //console.log(props.state.title)
        setMessageState(draft => {
            draft.value = props.state.title
        })

        const ourRequest = Axios.CancelToken.source()

        async function fetchCategories() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setMessageState(draft => {
                draft.isLoading = true
            })
            try {
                const response = await api.get('list_definitions_from_offers_ext/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(response.data)
                //console.log(`${response.data.length} categories found`)
                setMessageState(draft => {
                    draft.categoriesFetched = response.data
                })
            } catch (err) {
                console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch categories from pricedb!"
                })
            }
            setMessageState(draft => {
                draft.isLoading = false
            })
        }
        fetchCategories()
        return () => {
            ourRequest.cancel()
        }

    }, [props.state.msgId])

    useEffect(() => {
        if (props.state.msgId === -1) return

        const ourRequest = Axios.CancelToken.source()

        async function listFiles() {
            setMessageState(draft => {draft.isLoading = true})
            setFileStates(draft => {draft.savedFiles = []})
            try {
                const msgId = props.state.msgId //1625114
                const response = await api.get(
                    `list_uploaded_files_of_msg/${msgId}/`, appState.token.get_config, { cancelToken: ourRequest.token })
                const filesData = response.data

                filesData.forEach(file => {
                    const isImage = file.type.startsWith("image/")
	                const isExcel = isExcelFile(file.type)
                    if (isImage) {
                        setFileStates(draft => { draft.isLoading = true })
                    }
                    if (isImage || isExcel) {
                        const fileBlob = base64ToBlob(file.content, file.type)
                        const newFileData = {
                            name: file.name,
                            type: file.type,
                            content: URL.createObjectURL(fileBlob), // Create a preview URL
                            file: fileBlob,
                            isImage: isImage,
                            excelData: null, // Start with null, we'll populate this later
                        }
                        if (isExcel) {
                            parseExcelFile(fileBlob, (excelData) => {
                                // Once parsing is complete, update the state with the excelData
                                setFileStates(draft => {
                                    draft.savedFiles.push({ ...newFileData, excelData })
                                    draft.isLoading = false
                                })
                            })
                          } else {
                            // If it's not an Excel file, just add it to the state as is
                            setFileStates(draft => {
                                draft.savedFiles.push(newFileData)
                            })
                        }
                    }
                    if (isImage) {
                        setFileStates(draft => { draft.isLoading = false })
                    }
                })
            } catch (err) {
                console.log(err)
            }
            setMessageState(draft => {draft.isLoading = false})
        }
        listFiles()

        return () => {
            ourRequest.cancel()
        }
    }, [props.state.msgId])

    useEffect(() => {
        if (messageState.categoriesFetched === null || messageState.categoriesFetched === undefined) return
        const catResult = messageState.categoriesFetched
            .filter(item => item.def_type === "category")
            .sort((a, b) => a.sortorder - b.sortorder)
            .map(item => ({ value: item.id, label: item.def_value }))
        //console.log(catResult)
        const actResult = messageState.categoriesFetched
            .filter(item => item.def_type === "action")
            .map(item => ({ value: item.id, label: item.def_value }))
        setMessageState(draft => {
            draft.categoryList = catResult
            draft.actionList = actResult
        })
    }, [messageState.categoriesFetched])

    useEffect(() => {
        if (fieldsState.exportReqCnt === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function exportOffers() {
            setMessageState(draft => {
                draft.offerExportIsLoading = true
            })
            for (const [index, fieldsStateItem] of fieldsState.liste.entries()) {
                try {
                    const { key, exportId, ...fieldsStateCopy } = fieldsStateItem
                    if (exportId !== -1) { // already exported
                        continue
                    }
                    const response = await api.post(
                        'export_to_offers_ext/',
                        { 'offer_fields':fieldsStateCopy },
                        appState.token.post_config,
                        { cancelToken: ourRequest.token }
                    )
                    const offerId = response.data
                    appDispatch({
                        type: "success",
                        data: `Offer successfully recorded in pricedb table. record_id: ${offerId}`
                    })
                    setFieldsState(draft => {
                        draft.liste[index].exportId = offerId
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to create offer in pricedb table!"
                    })
                }
            }
            setMessageState(draft => {
                draft.offerExportIsLoading = false
            })
        }

        exportOffers()

        return () => {
            ourRequest.cancel()
        }

    }, [fieldsState.exportReqCnt])

    const handleVendorSearch = (value) => {
        if (!value) {
            setAcState(draft => {
                draft.vendorOptions = []
            })
            return
        }
    
        // Filter the predefined texts based on the user input
        const matchingSuggestions = vendorCommonTexts
            .filter((text) => text.toLowerCase().startsWith(value.toLowerCase()))
            .map((text) => ({ value: text }))

        setAcState(draft => {
            draft.vendorOptions = [...matchingSuggestions]
        })
    }

    function clearFields() {
        // not working
        /*fieldsState = produce(fieldsState, (draft) => {
            // Set the state to the initial state
            Object.keys(initialFieldsState).forEach((key) => {
                draft[key] = initialFieldsState[key]
            })
        })*/
        /*for (let index = 0; index < fieldsState.liste.length; index++) {
            setFieldsState(draft => {
                Object.keys(initialFieldsState).forEach(key => {
                    draft.liste[index][key] = initialFieldsState[key]
                })
            })
        }*/
        setFieldsState(draft => {
            draft.liste = [initialFieldsState]
        })
    }

    /*useEffect(() => {
        console.log(fieldsState)
    }, [fieldsState])*/

    function exportFields(selectedIndex) {
        let errorMessage = ""

        for (let index = 0; index < fieldsState.liste.length; index++) {
            if (selectedIndex !== -1 && index !== selectedIndex) {
                continue
            }

            if (fieldsState.liste[index].vendor === "") {
                errorMessage = "Vendor field can not be empty!"
            } else if (fieldsState.liste[index].category === null) {
                errorMessage = "Main Category must be selected!"
            } else if (fieldsState.liste[index].subcategory === null) {
                errorMessage = "Category must be selected!"
            } else if (fieldsState.liste[index].model === "") {
                errorMessage = "Model field can not be empty!"
            } else if (fieldsState.quantity === "") {
                errorMessage = "Quantity can not be empty!"
            } else if (fieldsState.liste[index].cost === "") {
                errorMessage = "Cost can not be empty!"
            } else if (fieldsState.liste[index].offer_exw === "") {
                errorMessage = "Ex work offer can not be empty!"
            } else if (fieldsState.liste[index].location === "") {
                errorMessage = "Location can not be empty!"
            } else if (fieldsState.liste[index].owner === "") {
                errorMessage = "Owned By field can not be empty!"
            } else if (fieldsState.liste[index].eta === "") {
                errorMessage = "ETA field can not be empty!"
            }

            let cost = fieldsState.liste[index].cost
            let offer_exw = fieldsState.liste[index].offer_exw
            let offer_landed = fieldsState.liste[index].offer_landed
            let offer_cnf = fieldsState.liste[index].offer_cnf
            let maxdiscount = fieldsState.liste[index].maxdiscount
            let nomianl = fieldsState.liste[index].nomianl

            if (errorMessage === "") {
                const quantity = parseInt(fieldsState.liste[index].quantity, 10)
                if (!Number.isInteger(quantity) || quantity <= 0) {
                    errorMessage = "Quantity must have positive integer value!"
                }

                if (errorMessage === "") {
                    const parsedCost = parseFloat(cost)
                    if (!isNaN(parsedCost) && isFinite(parsedCost)) {
                        cost= parsedCost.toFixed(2)
                    } else {
                        errorMessage = "Cost must have positive value!"
                    }
                }

                if (errorMessage === "") {
                    const parsedOffer_exw = parseFloat(offer_exw)
                    if (!isNaN(parsedOffer_exw) && isFinite(parsedOffer_exw)) {
                        offer_exw = parsedOffer_exw.toFixed(2)
                    } else {
                        errorMessage = "Ex work offer must have positive value!"
                    }
                }

                if (errorMessage === "" && offer_landed !== "") {
                    const parsedOffer_landed = parseFloat(offer_landed)
                    if (!isNaN(parsedOffer_landed) && isFinite(parsedOffer_landed)) {
                        offer_landed = parsedOffer_landed.toFixed(2)
                    } else {
                        errorMessage = "Landed offer must have positive value!"
                    }
                }

                if (errorMessage === "" && offer_cnf !== "") {
                    const parsedOffer_cnf = parseFloat(offer_cnf)
                    if (!isNaN(parsedOffer_cnf) && isFinite(parsedOffer_cnf)) {
                        offer_cnf = parsedOffer_cnf.toFixed(2)
                    } else {
                        errorMessage = "CNF offer must have positive value!"
                    }
                }

                if (errorMessage === "" && maxdiscount !== "") {
                    const parsedMaxdiscount = parseFloat(maxdiscount)
                    if (!isNaN(parsedMaxdiscount) && isFinite(parsedMaxdiscount)) {
                        maxdiscount = parsedMaxdiscount.toFixed(2)
                    } else {
                        errorMessage = "Max discount must have positive value!"
                    }
                }

                if (errorMessage === "" && nomianl !== "") {
                    const parsedNomianl = parseFloat(nomianl)
                    if (!isNaN(parsedNomianl) && isFinite(parsedNomianl)) {
                        nomianl = parsedNomianl.toFixed(2)
                    } else {
                        errorMessage = "Nominal field must have positive value!"
                    }
                }
            }

            if (errorMessage !== "") {
                appDispatch({
                    type: "error",
                    data: errorMessage
                })
                break
            }

            setFieldsState(draft => {
                draft.liste[index].cost = cost
                draft.liste[index].offer_exw = offer_exw
                draft.liste[index].offer_landed = offer_landed
                draft.liste[index].offer_cnf = offer_cnf
                draft.liste[index].maxdiscount = maxdiscount
                draft.liste[index].nomianl = nomianl
            })
        }

        if (errorMessage === "") {
            setFieldsState(draft => {
                draft.exportReqCnt++
            })
        }
    }

    function addNewRow() {
        setFieldsState(draft => {
            draft.liste.push(initialFieldsState)
        })
    }

    useEffect(() => {
        if (fieldsState.liste.length > 2) {
            const container = document.getElementById("offerRowsScrollContainer")
            if (container)
                container.scrollTop = container.scrollHeight
        }
    }, [fieldsState.liste])

    useEffect(() => {
        if (fileStates.files.length > 3) {
            const container = document.getElementById("uploadedFilesScrollContainer")
            if (container)
                container.scrollTop = container.scrollHeight
        }
    }, [fileStates.files])

    const renderFileIcon = (file) => {
        if (file.type.includes("image")) {
            return <FileImageOutlined style={{ fontSize: 24, color: "#1890ff" }} />
        }
        if (isExcelFile(file.type)) {
            return <FileExcelOutlined style={{ fontSize: 24, color: "#52c41a" }} />
        }
        return <UploadOutlined style={{ fontSize: 24 }} />
    }

    const parseExcelFile = (file, callback) => {
        setFileStates(draft => { draft.isLoading = true })

        const reader = new FileReader()
      
        reader.onload = (e) => {
            const arrayBuffer = e.target.result
            const workbook = XLSX.read(arrayBuffer, { type: "array" })
        
            // Loop through all sheet names
            const allSheetsData = workbook.SheetNames.map((sheetName) => {
                const sheet = workbook.Sheets[sheetName]
                
                // Convert the sheet to JSON with raw rows
                const sheetData = XLSX.utils.sheet_to_json(sheet, {
                    header: 1, // Treat everything as data initially
                    defval: "", // Default value for empty cells
                })

                // Check the number of rows
                let finalData = null
                if (sheetData.length === 1) {
                    // If there's only one row, treat it as data
                    finalData = sheetData
                } else if (sheetData.length > 1) {
                    // If there are multiple rows, treat the first row as headers
                    finalData = XLSX.utils.sheet_to_json(sheet, {
                        header: 0, // Use the first row as headers
                        defval: "", // Default value for empty cells
                    })
                }
                
                return {
                    sheetName,
                    data: finalData
                }
            })
            callback(allSheetsData)
        }
      
        reader.readAsArrayBuffer(file)
    }

    const handleFiles = (fileList) => {
        let numFilesUploaded = 0
        Array.from(fileList).forEach((file) => {
            const isImage = file.type.startsWith("image/")
            const isExcel = isExcelFile(file.type)
            if (isImage) {
                setFileStates(draft => { draft.isLoading = true })
            }
            if (isImage || isExcel) {
                const newFileData = {
                    name: file.name,
                    type: file.type,
                    content: URL.createObjectURL(file), // Create a preview URL
                    file,
                    isImage: isImage,
                    excelData: null, // Start with null, we'll populate this later
                }
                if (isExcel) {
                    parseExcelFile(file, (excelData) => {
                        // Once parsing is complete, update the state with the excelData
                        setFileStates(draft => {
                            draft.files.push({ ...newFileData, excelData })
                            draft.isLoading = false
                        })
                    })
                  } else {
                    // If it's not an Excel file, just add it to the state as is
                    setFileStates(draft => {
                        draft.files.push(newFileData)
                    })
                }
                numFilesUploaded += 1
            }
            if (isImage) {
                setFileStates(draft => { draft.isLoading = false })
            }
        })
        
        if (numFilesUploaded > 0) {
            success(`${numFilesUploaded} file(s) uploaded successfully.`)
        }
    }

    const handleDownload = (fileData) => {
        const a = document.createElement('a')
        a.href = fileData.content
        a.download = fileData.name
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const handleDragOver = (e) => {
        e.preventDefault()
        setFileStates(draft => { draft.isDragging = true })
    }
    
    const handleDragLeave = () => {
        setFileStates(draft => { draft.isDragging = false })
    }

    const handleDrop = (e) => {
        e.preventDefault()
        setFileStates(draft => { draft.isDragging = false })
        handleFiles(e.dataTransfer.files)
    }

    const handleBrowse = (e) => {
        handleFiles(e.target.files)
    }

    const handlePaste = (e) => {
        if (props.state.msgId !== -1) return

        setFileStates(draft => { draft.isLoading = true })
        const items = e.clipboardData?.items
        if (items) {
            for (let item of items) {
                if (item.type.startsWith("image/")) {
                    const file = item.getAsFile()
                    const imageURL = URL.createObjectURL(file)
                    const newFileData = {
                        name: file.name,
                        type: file.type,
                        content: URL.createObjectURL(file),
                        file,
                        isImage: true,
                        excelData: null,
                    }
                    setFileStates(draft => {
                        draft.files.push(newFileData)
                    })
                    break
                }
            }
        }
        setFileStates(draft => { draft.isLoading = false })
    }

    useEffect(() => {
        if (messageState.saveMsgReqCnt === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function saveCustomMessage() {
            setMessageState(draft => {
                draft.isLoading = true
            })
            try {
                const formData = new FormData()
                formData.append('message_content', messageState.value)
                formData.append('phone_number', messageState.phoneNumber)

                fileStates.files.forEach((fileData, index) => {
                    formData.append(`file_names`, fileData.name)
                    formData.append(`is_image`, fileData.isImage)
                    formData.append(`files`, fileData.file)
                })

                const response = await api.post(
                    'save_custom_message/',
                    formData,
                    appState.token.post_file_config,
                    { cancelToken: ourRequest.token }
                )
                setMessageState(draft => {draft.isMsgSaved = true})
                appDispatch({
                    type: "success",
                    data: "Custom message has been created"
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to create custom message!"
                })
            }
            setMessageState(draft => {
                draft.isLoading = false
            })
        }

        saveCustomMessage()

        return () => {
            ourRequest.cancel()
        }

    }, [messageState.saveMsgReqCnt])

    useEffect(() => {
        setMessageState(draft => {draft.isMsgSaved = false})
    }, [messageState.value, fileStates.files])

    function saveMessage() {
        if (messageState.value === "" && fileStates.files.length === 0) {
            appDispatch({
                type: "error",
                data: "Both message content and file list can not be empty!"
            })
            return
        }

        const phoneNumberObj = parsePhoneNumberFromString(messageState.phoneNumber)
        if (phoneNumberObj) {
            const phoneNumber = phoneNumberObj.number
            setMessageState(draft => {
                draft.phoneNumber = phoneNumber
                draft.saveMsgReqCnt++
            })
        } else {
            appDispatch({
                type: "error",
                data: "Phone number is not valid!"
            })
        }
    }

    function closeModal() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setDataSource(draft => {
            draft.isLoading = false
        })
    }

    function handleOk() {
        closeModal()
    }

    function handleCancel() {
        closeModal()
    }

    function footer() {
        let footerList = []
        /*footerList.push(
            <Tooltip key={"undo_tooltip"} title={"Retrieve original message"} placement="bottom">
                <Button key={"undo"} size='small' icon={<RetweetOutlined />}
                    onClick={(e) => {
                        setMessageState(draft => {
                            draft.value = props.state.title
                        })
                    }} />
            </Tooltip>
        )*/

        if (props.state.msgId === -1 && !fileStates.previewFile) {
            footerList.push(
                <Space key="save_message_key" style={{width: '275px', marginRight:"auto"}}>
                    <Input value={messageState.phoneNumber} onChange={(e) => setMessageState(draft => {draft.phoneNumber = e.target.value})}
                        size='small' style={{border: "2px solid red",}} placeholder="Phone number" />
                    <Tooltip title={messageState.isMsgSaved ? "Already saved" : ""}>
                        <Button type='primary' size='small' style={{marginRight:"auto"}}
                            disabled={!isPossiblePhoneNumber(messageState.phoneNumber) || messageState.isMsgSaved}
                            onClick={() => {saveMessage()}}>
                            Save Message
                        </Button>
                    </Tooltip>
                </Space>
            )
        }

        if (props.state.msgId === -1 &&
            (fileStates.files.length > 0 || messageState.value !== "" || messageState.phoneNumber !== "") &&
            !fileStates.previewFile
        ) {
            footerList.push(
                <Tooltip key="clear_all_key" title="Remove all files and clear text" placement='bottom'>
                    <Popconfirm
                        title="Are you sure?"
                        //description=""
                        onConfirm={(e) => {
                            setFileStates(draft => {draft.files = []})
                            setMessageState(draft => {
                                draft.value = ""
                                draft.phoneNumber = ""
                            })
                        }}
                        //onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" size="small" style={{paddingRight:"12px",}}>Clear All</Button>
                    </Popconfirm>
                </Tooltip>
            )
        }
        
        if (props.displayCopyButton && !fileStates.previewFile) {
            footerList.push(
                <Tooltip key={"copy_tooltip"} title={"Copy content"} placement="bottom">
                    <Button key={"copy"} size='small' icon={<CopyOutlined />} style={{marginLeft:"auto"}}
                        onClick={(e) => {
                            //copyToClipboard(cropText(props.record.message.cleaned_content, 100))
                            //copyToClipboard(props.state.title)
                            copyToClipboard(messageState.value)
                            success("Copied!")
                        }} />
                </Tooltip>
            )
        }
        if (fileStates.previewFile) {
            footerList.push(<div key="dummy_div" style={{height:"24px"}} />)
        }
        if (props.notDisplayOkButton)
            return footerList
        footerList.push(
            <Button key="submit" type="primary" onClick={handleOk}>
                OK
            </Button>
        )
        return footerList
    }

    const ExcelTable = ({ data, sheetName, index }) => {
        //console.log(data)
        const columns = data[0] ? Object.keys(data[0]).map((_key) => ({
            title: _key,
            dataIndex: _key,
            key: _key,
        })) : []
      
        return <Table dataSource={data} columns={columns} key={`table-${sheetName}-${index}`} />
    }
      
    const ExcelViewer = ({ sheetsData }) => (
        sheetsData ?
        <Tabs defaultActiveKey="1">
            {sheetsData.map((sheet, index) => (
                <Tabs.TabPane tab={sheet.sheetName} key={`tab-${sheet.sheetName}-${index}`}>
                    <ExcelTable data={sheet.data} sheetName={sheet.sheetName} index={index} />
                </Tabs.TabPane>
            ))}
        </Tabs> : ""
    )

    return (
        <Modal
            title=""
            open={props.state.modal.isOpen}
            onCancel={handleCancel}
            footer={<div style={{ display: "flex", justifyContent: "flex-start" }}>{messageState.isLoading ? <></> : footer()}</div>}
            style={{
                position: 'fixed',
                top: '30%',
                left: '0',
                transform: 'translate(0, -50%)',
                marginLeft: 250,
                marginTop: 100,
            }}
            >
                {contextHolder}
                <Space direction="horizontal">
                    {props.state.msgId === -1 || fileStates.savedFiles.length > 0 || fileStates.isLoading ?
                    fileStates.previewFile ?
                    <div style={{
                            width:"440px", height:"600px", paddingRight:'0px', maxHeight:'600px'
                        }}
                    >
                        <Space direction='vertical'>
                            <Tooltip title={fileStates.previewFile.name.length > 40 ? fileStates.previewFile.name : ""}>
                                <strong>{cropText(fileStates.previewFile.name, 40)}</strong>
                            </Tooltip>
                            <div style={{width:"470px", maxWidth: "470px", height:"580px", maxHeight: "580px", margin: "0 auto",
                                    overflow: "hidden", position: "relative", cursor:"pointer"}}>
                                {fileStates.previewFile.isImage ? (
                                    <TransformWrapper
                                        defaultScale={1}
                                        wheel={{ step: 0.5 }}
                                        pan={{
                                            disablePadding: true, // Allows panning beyond container bounds
                                        }}
                                        limitToBounds={false}
                                        minScale={0.25}
                                        maxScale={4}
                                    >
                                        {({ resetTransform }) => (
                                            <>
                                                <Tooltip title="Reset Image Position">
                                                    <Button type="primary" onClick={() => {resetTransform()}} style={{
                                                        position: "absolute", top: 10, left: 10, zIndex: 10,
                                                    }} icon={<PicCenterOutlined />} />
                                                </Tooltip>
                                                <TransformComponent>
                                                    <img
                                                        src={fileStates.previewFile.content}
                                                        alt={fileStates.previewFile.name}
                                                        style={{
                                                            maxWidth: "99%", maxHeight: "99%", objectFit: "contain", pointerEvents: "none",
                                                            border: "1px dashed #ccc",
                                                        }}
                                                    />
                                                </TransformComponent>
                                            </>
                                        )}
                                    </TransformWrapper>
                                ) : ""  /* (
                                    <ExcelViewer sheetsData={fileStates.previewFile.excelData} />
                                ) */}
                            </div>
                            <Space>
                                <Tooltip title="Go back" placement='left'>
                                    <CaretLeftOutlined style={{paddingTop:"6px", fontSize:"24px"}}
                                        onClick={()=>{setFileStates(draft => {draft.previewFile = null})}}/>
                                </Tooltip>
                            </Space>
                        </Space>
                    </div>
                    :
                    messageState.isLoading ? 
                    <div style={{width:"440px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <Spin size='large' />
                    </div>
                    :
                    <div style={{
                            width:"440px", height:"600px", paddingRight:'0px', maxHeight:'600px'
                        }}
                        ref={pasteAreaRef}
                        onPaste={handlePaste}
                    >
                        {props.state.msgId === -1 ?
                            <TextArea style={{resize:'none', height:'320px'}}
                                className='withprewrap'
                                onChange={(e) => {setMessageState(draft => {draft.value = e.target.value})}}
                                value={messageState.value}
                                placeholder='Copy image or text here'
                            /> :
                            <div style={{
                                    width:"440px", height:"320px", overflowY:'auto', paddingRight:'0px', maxHeight:'320px'
                                }}
                                className='withprewrap'
                            >
                                {messageState.value}
                            </div>
                        }
                        {fileStates.isLoading ?
                        <div style={{height:"250px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <Spin size='large' />
                        </div> :
                        <>
                        {props.state.msgId === -1 ?
                        <div
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            style={{
                                border: `2px dashed ${fileStates.isDragging ? "#1890ff" : "#d9d9d9"}`,
                                backgroundColor: fileStates.isDragging ? "#f0faff" : "white",
                                padding: 20,
                                textAlign: "center",
                                borderRadius: 4,
                                marginTop: 20,
                                position: "relative",
                                height: 35,
                                overflow: "hidden",
                                transition: "all 0.2s ease",
                            }}
                        >
                            <span>
                                {fileStates.isDragging ? "Release to upload files" :
                                    "Drag and drop files here or click to upload (image/excel)"}
                            </span>
                            <input
                                type="file"
                                multiple
                                onChange={handleBrowse}
                                style={{
                                    position: "absolute",
                                    top:0,
                                    left:0,
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0,
                                    cursor: "pointer",
                                }}
                            />
                        </div> : ""}
                        <div id="uploadedFilesScrollContainer" style={{ marginTop:"32px", display: "grid",
                                gridTemplateColumns:"repeat(3, 1fr)", height:props.state.msgId === -1 ? "160px" : "260px",
                                width:"440px", overflowY:'auto', }}>
                            {(props.state.msgId === -1 ? fileStates.files : fileStates.savedFiles).map((file, index) => (
                                <div
                                    key={index}
                                    style={{
                                        cursor: "pointer",
                                        textAlign: "center",
                                    }}
                                    onClick={() => setFileStates(draft => {draft.previewFile = file})}
                                >
                                    <Tooltip title={file.name.length > 15 ? file.name : ""}>
                                        {renderFileIcon(file)}
                                        <p style={{ fontSize: 12 }}>
                                            {cropText(file.name, 15)}
                                        </p>
                                    </Tooltip>
                                    <Space direction='horizontal' size='large'>
                                        {props.state.msgId === -1 ?
                                        <Tooltip title="Delete file" placement='bottom'>
                                            <CloseOutlined style={{color:"red", paddingBottom:"30px", fontSize:"18px"}} onClick={(e) => {
                                                e.stopPropagation()
                                                setFileStates(draft => {draft.files.splice(index, 1)})
                                            }}/>
                                        </Tooltip> : ""}
                                        <Tooltip title="Download file" placement='bottom'>
                                            <DownloadOutlined style={{color:"#7BB6E2", paddingBottom:"30px", fontSize:"18px"}} onClick={(e) => {
                                                e.stopPropagation()
                                                handleDownload(file)
                                            }}/>
                                        </Tooltip>
                                    </Space>
                                </div>
                            ))}
                        </div>
                        </>}
                    </div>
                    :
                    <div style={{
                            width:"440px", height:"600px", overflowY:'auto', paddingRight:'0px', maxHeight:'600px'
                        }}
                        className='withprewrap'
                    >
                        {messageState.value}
                    </div>}
                    {messageState.offerExportIsLoading ? <Spin size="large" /> :
                    <Space direction='vertical' size="small">
                        <div id="offerRowsScrollContainer"
                            style={{
                                height:"566px", maxHeight:"566px", width:"1350px", marginLeft:"50px",
                                paddingLeft:"10px", marginTop:"-20px", paddingTop:"20px",
                                backgroundColor:'white', borderRadius:"10px", overflowY:"auto"
                            }}
                        >
                            <Space size={"large"} direction="vertical">
                                {fieldsState.liste.map((_, idx) => {
                                    return (
                                        <OfferExportRow
                                            key={idx}
                                            index={idx}
                                            acState={acState}
                                            handleVendorSearch={handleVendorSearch}
                                            fieldsState={fieldsState}
                                            setFieldsState={setFieldsState}
                                            messageState={messageState}
                                            setMessageState={setMessageState}
                                            exportFields={exportFields}
                                        />
                                    )
                                })}
                                {fileStates.previewFile && !fileStates.previewFile.isImage ?
                                <div style={{
                                    marginTop:"-36px", maxHeight:"270px", overflowY:"auto", maxWidth:"1314px",
                                }}>
                                    <ExcelViewer sheetsData={fileStates.previewFile.excelData} />
                                </div>
                                : fieldsState.liste.length === 1 ?
                                <div style={{
                                    display: "flex", justifyContent: "center", alignItems: "center", height: "200px",
                                }}>
                                    <Tooltip title="Add Row">
                                        <PlusCircleOutlined style={{fontSize:"36px", cursor:"pointer", color:"#73D673"}} onClick={() => addNewRow()}/>
                                    </Tooltip>
                                </div>
                                : ""}
                            </Space>
                        </div>
                        <div style={{display:"flex", flexDirection:"row", gap:"10px", backgroundColor:"white",
                            marginLeft:"50px", marginTop:"-4px", paddingLeft:"10px", paddingTop:"10px", height:"40px", borderRadius:"6px"
                        }}>
                            <Button type="primary" onClick={() => addNewRow()}>
                                Add Row
                            </Button>
                            <Popconfirm
                                title="Are you sure?"
                                //description=""
                                onConfirm={(e) => { clearFields() }}
                                //onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary">
                                    Remove All
                                </Button>
                            </Popconfirm>
                            <Button type="primary" onClick={() => exportFields(-1)}>
                                Export All
                            </Button>
                        </div>
                    </Space>}
                </Space>
        </Modal>
    )
}

export default MessageContentModal
